import React  from "react";
import { Link } from "react-router-dom";

const HomeActions = () => { 
  
    return (
      <section id="actions" className="py-3 mb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-0">
              <Link to={"/search"} className="btn btn-warning btn-block">
                <i className="fas fa-file-alt"></i>&nbsp;Nuevo Pedido
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }

  export default HomeActions;
