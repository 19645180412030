import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";

import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Global from "../components/Global";
import imageExists from "image-exists";
import ImageZoomViewer from "../components/ProductImageZoomViewer";
import Modal360Viewer from "../components/Product360Viewer";
import ProductApplications from "../components/ProductApplications";
import { getInventarioDetalladoCEDIS } from "../repository";

const Stock = (props) => {
  const [codigo, setCodigo] = useState("");
  const [loading, setLoading] = useState(0);
  const [sucursales, setSucursales] = useState([]);
  const [status, setStatus] = useState("");
  const [index] = useState(props.index);
  const navBarRef = React.useRef(NavBar);
  const [Descripcion, setDescripcion] = useState("");
  const [item, setItem] = useState([]);
  const [imageSource, setImageSource] = useState("");
  const [is360, set360] = useState(false);
  //const [isImageZoom, setIsImageZoom] = useState(false);
  const [isModal360Open, setIsModal360Open] = useState(false);
  const [codeSelected, setCodeSelected] = useState(null);
  const isImageZoom = useRef(false);

  const changeState = (e) => {
    setCodigo(e);
    setSucursales({});
    setStatus("");
  };

  function openAppsModal(e) {
    e.preventDefault();
    setCodeSelected(item);
    //codeSelected.current = props.item;
  }

  const closeAppsModal = () => {
    setCodeSelected(null);
  };

  useEffect(() => {
    //console.log(codigo);
    navBarRef.current.getCartItems();
  }, []);

  const openImageZoomModal = () => {
    //console.log(imageSource);
    //if (imageSource !== Global.foto_nd) setIsImageZoom(true);
    if (imageSource !== Global.foto_nd) isImageZoom.current = true;
    //console.log("clic en zoom");
  };

  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setSucursales({});
    setStatus("");
    set360(false);
    setItem([]);
    close360Modal();
    isImageZoom.current = false;

    if (validator.current.allValid()) {
      getInventarioDetalladoCEDIS(codigo.toUpperCase())
        .then((response) => {
          //console.log(response)
          if (response.status === 200) {
            if (response.data.result[0].Codigo !== null) {
              setDescripcion(response.data.result[0].Descripcion);
              setStatus("success");
              setSucursales(response.data.result);
              setItem(response.data.result[0]);
              renderImageSource(codigo);
              setLoading(0);
            } else {
              setLoading(0);
              Swal("Aviso", "El código no existe.", "info");
            }
          } else {
            Swal(
              "Aviso",
              "No se puede mostrar información de [Inventarios] en este momento.",
              "info"
            );
            setLoading(0);
          }
        })
        .catch((err) => {
          Swal("Aviso", "Error al obtener la información [Inventarios]", "error");
          setLoading(0);
        });
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };
  const open360Modal = (e) => {
    e.preventDefault();
    setIsModal360Open(true);
  };

  const close360Modal = () => {
    setIsModal360Open(false);
  };

  const renderImageSource = (codigo) => {
    imageExists(
      Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg",
      function (exists) {
        if (exists) {
          setImageSource(
            Global.urlFotos360 +
              codigo.toUpperCase() +
              "/images/img_0_0_0.jpg"
          );
          set360(true);
        } else {
          imageExists(
            Global.fotos_URL + codigo.toLowerCase().replace("-", "_") + ".jpg",
            function (exists) {
              if (exists) {
                setImageSource(
                  Global.fotos_URL +
                    codigo.toLowerCase().replace("-", "_") +
                    ".jpg"
                );
              } else {
                setImageSource(Global.foto_nd);
              }
            }
          );
        }
      }
    );
  };

  const closeImageZoomModal = () => {
    isImageZoom.current = false;
    // setIsImageZoom(false);
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Código inválido",
      },
    })
  );

  return (
    <div>
      {/* NAVBAR */}
      <NavBar ref={navBarRef} />

      {/* HEADER */}
      <Header
        title="Inventarios"
        class1="row py-1 bg-primary text-white"
        class2="col-md-6"
        class3="fa fa-cubes"
      />
      <section id="stock">
        <div className="container">
          <form onSubmit={search}>
            <div className="row mt-3">
              <div className="col-md-1 mt-2">
                <h6>Código:</h6>
              </div>
              <div className="col-md-3 mb-3">
                <input
                  name="txtCodigo"
                  type="text"
                  className="form-control"
                  defaultValue=""
                  /* ref={codigoRef}*/
                  autoComplete="off"
                  placeholder="Capture el Código"
                  onChange={(e) => changeState(e.target.value)}
                  maxLength="20"
                />
                {validator.current.message(
                  "txtCodigo",
                  codigo,
                  "required|alpha_num_dash"
                )}
              </div>
              <div className="col-md-3">
                <button className="btn btn-secondary btn-block" type="submit">
                  <i className="fas fa-search mr-1"></i>Buscar
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3 mt-3">
                {loading === 1 && (
                  <div className="text-center">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                )}
                {status === "success" && loading === 0 && (
                  <div className="card">
                    <table className="table table-sm table-striped">
                      <thead className="table-dark ">
                        <tr>
                          <th className="w-25 text-center">Producto</th>
                          <th className="col-md-auto">Descripción</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={item.idAlmacen}>
                          <td>
                            <div className="row justify-content-center">
                              <div className="col text-center">
                                <img
                                  className="img-thumbnail"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: 100 + "%",
                                    minWidth: 160 + "px",
                                    height: 170 + "px",
                                    objectFit: is360 ? "cover" : "contain",
                                  }}
                                  src={imageSource}
                                  alt={item.Codigo}
                                  onClick={() => openImageZoomModal()}
                                  data-toggle="modal"
                                  data-target={"#modalImageZoom" + item.Codigo}
                                />
                                {isImageZoom && (
                                  <ImageZoomViewer
                                    item={item}
                                    imageSource={imageSource}
                                    closeModal={closeImageZoomModal}
                                  />
                                )}
                                {codeSelected && (
                                  <ProductApplications
                                    codigo={item.Codigo}
                                    closeModal={closeAppsModal}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col text-center mt-2 mb-2 ">
                                <span className="col text-center">
                                  <button
                                    className="btn btn-outline-warning btn-sm rounded-pill mr-2"
                                    onClick={(e) => openAppsModal(e)}
                                    data-toggle="modal"
                                    data-target={"#modalApps" + item.Codigo}
                                  >
                                    APS
                                  </button>
                                  {is360 && (
                                    <button
                                      className="btn btn-outline-info btn-sm rounded-pill"
                                      onClick={(e) => open360Modal(e)}
                                      data-toggle="modal"
                                      data-target={
                                        "#modal360" + item.Codigo + index
                                      }
                                    >
                                      360
                                    </button>
                                  )}
                                  {isModal360Open && (
                                    <Modal360Viewer
                                      index={index}
                                      item={item}
                                      catalogo={true}
                                      closeModal={close360Modal}
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>{Descripcion}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table-xs table-striped w-100">
                      <thead className="table-dark">
                        <tr>
                          <th className="text-center">Sucursal</th>
                          <th className="text-center">Inventario</th>
                          {/*  <th className="text-center">Stock</th> */}
                          {/*  <th className="text-center">Comprometido</th>  */}
                          {/* <th className="text-center">Disponible</th>    */ }
                        </tr>
                      </thead>

                      <tbody>
                        {sucursales.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td className="text-center">{item.Almacen}</td>
                              <td className="text-center">
                                {item.Disponible >= 10 ? (
                                  <span className="badge badge-success mt-1">
                                    Disponible
                                  </span>
                                ) : item.Disponible > 0 &&
                                  item.Disponible < 10 ? (
                                  <span className="badge badge-warning mt-1">
                                    Bajo
                                  </span>
                                ) : (
                                  <span className="badge badge-danger mt-1">
                                    No Disponible
                                  </span>
                                )}
                              </td>
                              {/*  <td className="text-center">
                              {" "}
                              {item.EnStock} 
                              </td> */}
                              {/*    <td className="text-center">
                              {" "}
                               {item.Comprometido} 
                            </td> */}
                            {/* <td className="text-center">{item.Disponible}</td>   */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Stock;
