import React, { useEffect } from "react";
const ProductImageZoomViewer = (props) => {
  useEffect(() => 
  {    
  },[])
  return (
    <div
      className="modal fade"
      id={"modalImageZoom" + props.item.Codigo}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby={"modalImageZoom" + props.item.Codigo + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-center"
              id={"modalImageZoom" + props.item.Codigo + "Label"}
            >
              {props.item.Familia} {props.item.Codigo} (Zoom)
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <div id="zoomViewer">
              <img
                id="zoomImage"
                alt=""
                src={props.imageSource}
                style={{
                  maxWidth: 100 + "%",
                  height: 400 + "px",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={props.closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductImageZoomViewer;