import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import readXlsxFileBrowser from "read-excel-file";
import global from "../components/Global";
import {
  getProductosPlantillaByCode,
} from "../repository";

const Template = () => {
  const [input, setInput] = React.useState(null);
  const [productos, setProductos] = useState([]);
  //const [idSucursal] = useState(JSON.parse(localStorage.getItem("sucursal")));
  const navBarRef = React.useRef(NavBar);
  const [equivalencias, setEquivalencias] = useState(false);
  const [success, setSuccess] = useState(null);
  const [plantillaErronea, setPlantillaErronea] = useState(false);
  const productosValidos = useRef(0);
  const [productoCargado, setProductoCargado] = useState(0);

  const schema = {
    Producto: {
      prop: "Producto",
      type: String,
    },
    Cantidad: {
      prop: "Cantidad",
      type: Number,
    },
  };

  const cargarExcel = async (e) => {
    e.preventDefault();

    try {
      let xml = await readXlsxFileBrowser(input.files[0], {
        schema,
        transformData(data) {
          // Formateamos el resultado.
          return [["Producto", "Cantidad"]].concat(data);
        },
      });

      //Se remueven las primeras 2 filas del excel y el LOGO
      const elementosABuscar = Object.assign([], xml.rows);
      elementosABuscar.splice(0, 2);

      if (elementosABuscar.length < global.cantidadMaximaPlantilla) {
        setProductos(elementosABuscar);
      } else {
        Swal(
          "Aviso.",
          "Solo se permite la carga de hasta " +
            global.cantidadMaximaPlantilla +
            " códigos por plantilla.",
          "info"
        );
        setPlantillaErronea(true);
      }
    } catch (ex) {
      Swal(
        "Error.",
        "Archivo No Valido. Favor de descargar la nueva Plantilla.",
        "error"
      );
      setPlantillaErronea(true);
    }
  };

  useEffect(() => {
    if (
      success === "success" &&
      productoCargado >= productosValidos.current &&
      productosValidos.current > 0
    ) {
      console.log(productoCargado + " de " + productosValidos.current);
      setTimeout(function () {
        window.location = "/cart";
      }, 1000);
    } else {
      setInput(document.getElementById("input"));
    }
  }, [success, productoCargado]);

  async function CargaItemsAlCarrito() {
    // let promise = new  Promise( (resolve, reject)  => {
    var contador = 0;
    var itemsValidos = 0;
    let promise;
    let promise2;
    //validamos que se haya ingresado informacion en el excel
    if (productos.length > 0) {
      productos.forEach((item, index) => {
        var cant = item.Cantidad;
        item.Cantidad = 0;

        promise = new Promise((resolve, reject) => {
          getProductosPlantillaByCode(equivalencias ? 1 : 0, item.Producto)
            .then(async function (response) {
              //validamos la respuesta de un producto que si existe
              if (response.result !== undefined) {
                await response.result.forEach((itemWebAPI) => {
                  itemsValidos = itemsValidos + 1;
                  productosValidos.current = itemsValidos;
                  itemWebAPI.Cantidad = item.Cantidad; 
                      try {
                        let cart = sessionStorage.getItem("cart")
                          ? JSON.parse(sessionStorage.getItem("cart"))
                          : {};
                        let idProducto = itemWebAPI.idProducto;
                        cart[idProducto] = cart[idProducto]
                          ? cart[idProducto]
                          : itemWebAPI;
                        cart[idProducto].Inventario = -1;
                        cart[idProducto].Cantidad += cant;
                        sessionStorage.setItem("cart", JSON.stringify(cart));
                        contador = contador + 1;
                        setProductoCargado(contador);
                        navBarRef.current.getCartItems();
                      } catch (error) {
                          console.log(error);
                      }
                });
                resolve();
              }
            })
            .catch((error) => {
              console.log(error);
            });

          if (productos.length - 1 === index) {
            console.log("HE PROCESADO TODOS LOS PRODUCTOS");
          }
        });
      });
    } else {
      Swal(
        "Aviso.",
        "Favor de validar la información de la Plantilla.",
        "info"
      );
    }
    let result = await promise;
    let result2 = await promise2;

    console.log(result);
    console.log(result2);
  }

  const cargarItems = async (e) => {
    e.preventDefault();

    await CargaItemsAlCarrito().then(() => {
      setSuccess("success");
    });
  };

  return (
    <div>
      {/* NAVBAR */}
      <NavBar ref={navBarRef} />
      {/* HEADER */}
      <Header
        title="Subir Plantilla"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-upload"
      />
      {/* CONTENT */}
      <section id="plantilla">
        <form>
          {
            /*success === "success" &&*/ productoCargado > 0 ? (
              <div className="text-center mb-3 mt-3">
                <h6>Espere por favor... Actualizando sus productos</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col"></div>
                  <div className="col-xs">
                    <a
                      className={
                        plantillaErronea === false
                          ? "btn btn-link"
                          : "btn btn-danger"
                      }
                      href={process.env.PUBLIC_URL + "/plantilla.xlsx"}
                    >
                      Bajar Plantilla
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Ingrese el archivo Excel con su pedido:
                  </div>
                </div>
                <div className="row">
                  <div className="col mt-2">
                    <input
                      type="file"
                      className="form-control-file"
                      accept=".xlsx"
                      id="input"
                      name="input"
                      onChange={cargarExcel}
                    />
                  </div>
                  <div className="col-md-auto mt-2">
                    <div className="row justify-content-center">
                      <div className="col">
                        <button
                          className="btn btn-warning btn-block"
                          id="btnCargar"
                          name="btnCargar"
                          onClick={(e) => cargarItems(e)}
                        >
                          <i className="fa fa-upload"></i>
                          &nbsp; Subir Archivo
                        </button>
                      </div>
                      <div className="col-md-auto">
                        <div className="col text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={equivalencias.current}
                            onChange={() => {
                              setEquivalencias(!equivalencias);
                            }}
                            name="Equivalencias"
                          />
                          <label className="form-check-label">
                            Procesar Equivalencias
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                 
              </div>
            )
          }
        </form>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Template;
