import React from "react";
import { useTable, usePagination } from "react-table";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { numberFormat } from "../repository";

export default function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <>
      <table className="table-sm table-striped w-100" {...getTableProps()}>
        <thead className="table-dark">
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">Total</th>
            <th className="text-center">Fecha</th>
            <th className="">&nbsp;</th>
          </tr>
        </thead>
        {/*  agregar cabeceras de manera automatica
      <thead className="table-dark">
        {headerGroups.map(headerGroup => (
          <tr>
            {headerGroup.headers.map(column => (
              <th className="text-center" {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
            */}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === "boton") {                 
                    return (
                      <td className="text-center" {...cell.getCellProps()}>
                        <Link
                          to={"/orderDetails/" + row.cells[0].value + "/2"}
                          className="btn btn-secondary"
                        >
                          <i className="fas fa-angle-double-right"></i>
                          {" "}
                          Detalle
                        </Link>
                      </td>
                    );
                  }
                  if (cell.column.id === "Fecha") {                   
                    return (
                      <td className="text-center" {...cell.getCellProps()}>
                        <Moment format="DD/MM/YY">{row.cells[2].value}</Moment>
                      </td>
                    );
                  }
                  if (cell.column.id === "Total") {                   
                    return (
                      <td className="text-center" {...cell.getCellProps()}>
                        <strong>${numberFormat(row.cells[1].value)}</strong>
                      </td>
                    );
                  }
                  if (cell.column.id === "idPedido") {
                    //console.log(row.cells);
                    return (
                      <td className="text-center" {...cell.getCellProps()}>
                        {row.cells[0].value}
                      </td>
                    );
                  }
                  return (
                    <td className="text-center" {...cell.getCellProps()}>
                      {cell.render("Cell")}{" "}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="container">
        <div className="row mt-2">                            
            <div className="col-md text-center">
              <button
                className="btn btn-warning"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <strong>{"<<"} </strong>
              </button>{" "}
              <button
                className="btn btn-warning "
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <strong> {"<"}</strong>
              </button>{" "}
              Página{" "}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>{" "}
              <button
                className="btn btn-warning"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <strong>{">"}</strong>
              </button>{" "}
              <button
                className="btn btn-warning"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <strong> {">>"}</strong>
              </button>{" "}
           
          </div>
          <div className="col-md mt-2 text-center">
         
            {" "}Tamaño de Página:{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
            
              {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                {pageSize}
                </option>
              ))}
            </select>
          </div>
          
                   
        </div>
      </div>
    </>
  );
}
