var Global = {
  //API REST Desarrollo
  //apiRESTUrl: "http://localhost:3001/api", //Azure Node.JS  <-- local
  //apiRESTInventariosUrl :"http://localhost:3002/api",

  //API REST QA
  //apiRESTUrl: "https://pruebas2-node.azurewebsites.net/api", //Azure Node.JS -> pruebas pre-productivo
 
  //API REST Produccion
  apiRESTUrlNET: "https://webapi-pedidosmayoreo-net.azurewebsites.net/api", //Usuarios ASP.NET Membership
  apiRESTUrl: "https://webapi-pedidosmayoreo-node.azurewebsites.net/api", //Azure Node.JS
  apiRESTInventariosUrl :"https://webapi-inventarios-node.azurewebsites.net/api", //Azure Node.JS <- Api  produccion nueva implementacion.


 
  
  /*************** Generales *****************/
  fotos_URL: "https://storagegovi.blob.core.windows.net/fotos/i_",
  urlFotos360: "https://storagegovi.blob.core.windows.net/360/12-2021/",

  foto_nd: "https://storagegovi.blob.core.windows.net/fotos/img_nd.png",
  axios_APIKey: "95eb7fc3-b60c-4eed-bf6b-32ce8d0ce62a",
  cantidadMaximaPlantilla: "1000",
};

export default Global;
