import React, { useState , useEffect} from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import Swal from "sweetalert";

import Header from "../components/Header";
import HomeActions from "../components/HomeActions";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import { getPedidos, numberFormat } from "../repository";

const Home = () => {
  
  const [pedidos, setPedidos]= useState([]);
  const [status, setStatus] = useState(null);  

  const navBarRef = React.useRef(NavBar);
  
  useEffect(() => {  
    obtienePedidos();   
    navBarRef.current.getCartItems();
  }, []);
  

  const obtienePedidos = () => {        
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    localStorage.setItem("sucursal", JSON.stringify(user[0].idSucursal));     
    var idCliente = user[0].idClienteGovi;
    var idAlmacen = user[0].idSucursal;
    getPedidos(idCliente, idAlmacen,10)
      .then(response => {
        if (response.status === "success") {
          setStatus("success");
          setPedidos(response.result);         
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch(err =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
      
  };

    return (
      <div>
        {/* NAVBAR */}
        <NavBar ref={navBarRef} />   

        {/* HEADER */}
        <Header
          title="Pedidos Mayoreo"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-store-alt"
        />

        {/* ACTIONS */}
         <HomeActions />  

        {/* PEDIDOS */}
        <section id="orders">
          <div className="container">
            <div className="row">
              <div className="col-md-9 mb-3">
                <div className="card">
                  <div className="card-header">
                    <h5>Últimos 10 Pedidos</h5>
                  </div>

                  <table className="table-sm table-striped w-100">
                    <thead className="table-dark">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Fecha</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    {/*Listado de documentos del API RESTful de NodeJS*/}
                    {status === "success" && (
                      <tbody>
                        {pedidos.map(item => {
                          return (
                            <tr key={item.idPedido}>
                              <td className="text-center">{item.idPedido}</td>
                              <td className="text-right">
                                <strong>${numberFormat(item.Total)}</strong>
                              </td>
                              <td className="text-center">
                                <Moment format="DD/MM/YY">
                                  {item.Fecha}
                                </Moment>
                              </td>
                              <td className="text-center">
                                <Link
                                  to={"/orderDetails/" + item.idPedido + "/1"}
                                  className="btn btn-secondary"
                                >
                                  <i className="fas fa-angle-double-right"></i>{" "}
                                  Detalle
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              {/* SIDEBAR*/}
              <SideBar /> 
            </div>
          </div>
        </section>

        {/* FOOTER */}
         <Footer /> 
      </div>
    );
  }


export default Home;
