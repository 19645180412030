import React, { Component } from "react";

class Header extends Component {
  render() {
    var user = JSON.parse(sessionStorage.getItem("usuario"));  

    return (
      <header id="main-header">
        <div className="container">
          <div className={this.props.class1}>
            <div className={this.props.class2}>
              <h4 className="mt-2">
                <i className={this.props.class3}></i>
                &nbsp;{this.props.title}
              </h4>
            </div>
            <div className="col-md-6 py-1">
              <div className="float-right">               
              </div>
            </div>
          </div>
          <div className="row bg-light py-1">
            <div className="col-md-12">
              <div className="float-right">
                <i className="fas fa-user"></i>&nbsp;
                {user
                  ? user[0].Nombre.length > 30
                    ? user[0].Nombre.substring(0, 30) + "..."
                    : user[0].Nombre
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
