import React, { useEffect } from "react";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { crearUser } from "../repository";


const Users = (props) => {
    const navBarRef = React.useRef(NavBar);
    
    
    useEffect(() =>
    {       
            navBarRef.current.getCartItems();
            crearUser()
            .then((response) => {
              if (response.status === 200) {                
                Swal("Aviso",  response.data, "info");
              }
              else
              {
                Swal("Error", "Error al crear uno o mas usuarios.", "error");
              }
            });                                   
    },[])

    return (
        <div>
          {/* NAVBAR */}
          <NavBar ref={navBarRef} />    
          {/* HEADER */}
          <Header
            title="Usuarios"
            class1="row py-1 bg-primary text-white"
            class2="col-md-6"
            class3="fa fa-users"
          />
          <section id="users">          
          </section>    
          {/* FOOTER */}
          <Footer />
        </div>
      );
    };
    

export default Users;