import React, { Fragment, useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import CartItem from "../components/CartItem";
import {
  numberFormat,
  agregaPedidos,
  agregaDetallePedidos,
} from "../repository";

const Cart = () => {
  const navBarRef = React.useRef(NavBar); 
  const [status, setStatus] = useState(null);
  const [productos, setProductos] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [items, setItems] = useState(0); 
  const [consignado, setConsignado] = useState("");
  const [orderStatus, setOrderStatus] = useState("");   
  const [buttonDisabled, setButtonDisabled] = useState(false); 

  useEffect(() => {
    if (orderStatus === "success") {
      sessionStorage.removeItem("cart");    
    }else{
    validator.current.hideMessages();
    getCart();
    }
  }, [orderStatus]);

  const getCart = () => {
    navBarRef.current.getCartItems();
    if (sessionStorage.getItem("cart") !== null) {
      var cart = JSON.parse(sessionStorage.getItem("cart"));    
      var subtotal = 0;
      var items = 0;
      Object.entries(cart).forEach(([key, item]) => {
        //console.log("Object entries:", item)
      //  if (item.Inventario >= item.Cantidad) {
          subtotal += item.PrecioPesosMayoreo * item.Cantidad;
          items += item.Cantidad;
        //} else {
         // subtotal += 0;
         // items += 0;
       // }
      });
      setStatus(Object.keys(cart).length > 0 ? "success" : "failed");
      setProductos(cart);
      setSubtotal(subtotal);
      setItems(items);
    }
  };

  const saveOrder = async (e) => {
    
    e.preventDefault();  
    setButtonDisabled(true);     
    var user = JSON.parse(sessionStorage.getItem("usuario"));    
    var idClienteGovi = user[0].idClienteGovi;
    var idClienteWeb = user[0].idCliente;
    var idSucursal = JSON.parse(localStorage.getItem("sucursal"));
    if (validator.current.allValid()) {
      var cart = productos;
      var idPedido = await agregaPedidos(
        idClienteGovi,
        idSucursal,
        subtotal,
        "PEDIDO WEB " +  consignado ,
        idClienteWeb
      );
      //console.log("idPedidoGuardado: " + idPedido);     
      if (idPedido > 0) {
        Object.entries(cart).map(async ([key, item]) => {
          //Solo los productos que estan disponibles
         // console.log(item);
       //   if (item.Inventario >= item.Cantidad) {
            await agregaDetallePedidos(
              idPedido,
              item.Codigo,
              item.Descripcion
                ? item.Descripcion
                : item.Familia +
                    " / " +
                    item.Marca +
                    " / " +
                    item.TipoVehiculo +
                    " / " +
                    item.Modelo +
                    " / " +
                    item.Posicion +
                    " / " +
                    item.Condicion,
              item.Cantidad,
              item.PrecioPesosMayoreo,
              item.idMarcaProducto,
              item.idMarcaProducto + " - " + item.MarcaProducto
            );           
        //  }
        });
        Swal(
          "Pedido creado",
          "El Pedido se ha generado correctamente",
          "success"
        );      
        setStatus("success");
        setOrderStatus("success");      
      }
    } else {
      validator.current.showMessages();     
    }
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );
   
  const removeFromCart = (item) => {
    let cart = productos;
    let idProducto = item.idProducto;
    delete cart[idProducto];
    sessionStorage.setItem("cart", JSON.stringify(cart));
    getCart();
  };

  const updateCart = (item, cant) => {
    let cart = productos;
    let idProducto = item.idProducto;
    cart[idProducto].Cantidad = cant;
    sessionStorage.setItem("cart", JSON.stringify(cart));
    getCart();
  };

  const changeState = (e) => {
    setConsignado(e.target.value);
  };
  
   if (orderStatus ==="success") return ( <Redirect to="/home"/>)
   return (   
    <div>
      {/* NAVBAR */}
      <NavBar ref={navBarRef} />
      {/* HEADER */}
      <Header
        title="Carrito de Compras"
        class1="row py-1 bg-success text-white"
        class2="col-md-6"
        class3="fa fa-shopping-cart"
      />

      {/* CONTENT */}
      <section id="items">
        <div className="container">
          {status !== "success" && (
            <div className="row mt-3">
              <div className="col-md-3"></div>
              <div className="col-md-6 text-center">
                <small>
                  <strong>El Carrito de Compras está vacío.</strong>
                </small>
                <div className="col-md-3"></div>
              </div>
            </div>
          )}

          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-12">
                  <ul className="list-group">
                    {Object.entries(productos).map(([key, value]) => (
                      <CartItem
                        // ref="cartItemRef"
                        item={value}
                        key={key}
                        remove={removeFromCart}
                        update={updateCart}
                      />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 text-right">
                  <h5>
                    <small>
                      <strong>Subtotal</strong>
                    </small>
                    <span className="badge badge-success ml-1 mr-1">
                      {items}
                    </span>
                    <small>
                      <strong>Productos:</strong>
                    </small>
                    <span className="badge badge-pill badge-warning ml-2 mr-3">
                      ${numberFormat(subtotal)} + IVA
                    </span>
                  </h5>
                </div>
              </div>
              <form onSubmit={saveOrder}>
                <div className="form-row">
                  <div className="col-md-10 mt-3">
                    <input
                      name="txtConsignado"
                      type="text"
                      className="form-control"                                      
                      value={consignado}
                      autoComplete="off"                      
                      placeholder="Capture sus comentarios (Ej: Consignado)"
                      onChange={(e) => {
                        changeState(e);
                      }}
                      maxLength="300"
                    />
                    {/*  // QUITAMOS LA VALICACIóN DE CONSIGNADO
                     {validator.current.message(
                      "txtConsignado",
                      consignado,
                      "required|string"
                    )}
                    */}
                  </div>
                  <div className="col-md-2 mt-3">
                    <button className="btn btn-warning btn-block" disabled= {buttonDisabled} type="submit">
                      <i className="fas fa-paper-plane mr-1"></i>Enviar
                    </button>
                  </div>
                </div>
              </form>
            </Fragment>
          )}
        </div>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
                    
};

export default Cart;
