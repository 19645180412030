import axios from "axios";
import global from "./components/Global";
//import cors from "cors";



const config = 
{  headers : {      
        "ApiKey": "95eb7fc3-b60c-4eed-bf6b-32ce8d0ce62a",           
        }
              
};

export function login(data) {
  var usuario = data.usuario;
  var contrasenia = data.contrasenia;

  var apiCall =
    global.apiRESTUrl + "/obtieneUsuario/" + usuario + "/" + contrasenia;

  return (
    axios
      .get(apiCall,config)
      .then((response) => {
        if (response.data.status === "success") {
          //Usuario
          sessionStorage.setItem(
            "usuario",
            JSON.stringify(response.data.result)
          );
          //Token
          sessionStorage.setItem("x-access-token", response.data.token);
          //Token expira en 12 horas
          sessionStorage.setItem(
            "x-access-token-expiration",
            Date.now() + 12 * 60 * 60 * 1000
          );
        }
        return response.data;
      })
      //.catch(err => Promise.reject(err));
      .catch((error) => {
        return error.response.data;
      })
  );
}

export function loginNew2(usuario, contrasenia) {
  var apiCall = global.apiRESTUrlNET + "/login/" + usuario + "/" + contrasenia;
  return axios
    .get(apiCall)
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem("usuario", JSON.stringify(response.data));
        //Token
        sessionStorage.setItem("x-access-token", response.data.token);
        //Token expira en 12 horas
        sessionStorage.setItem(
          "x-access-token-expiration",
          Date.now() + 12 * 60 * 60 * 1000
        );
      }
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function isAuthenticated() {
  //return false;

  return (
    sessionStorage.getItem("x-access-token") &&
    sessionStorage.getItem("x-access-token-expiration") > Date.now()
  );
}

export function getSucursales(latitude, longitude) {
  var apiCall =
    global.apiRESTUrl + "/obtieneSucursales/" + latitude + "/" + longitude;
  return axios
    .get(apiCall,config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getMarcas() {
  var apiCall = global.apiRESTUrl + "/obtieneMarcas";
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getVehiculos(idMarca) {
  var apiCall = global.apiRESTUrl + "/obtieneVehiculos/" + idMarca;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getModelos(idTipoVehiculo) {
  var apiCall = global.apiRESTUrl + "/obtieneModelos/" + idTipoVehiculo;
  console.log(apiCall);
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getFamilias(idTipoVehiculo, modelo) {
  var apiCall =
    global.apiRESTUrl + "/obtieneFamilias/" + idTipoVehiculo + "/" + modelo;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductos(idTipoVehiculo, idFamilia, modelo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductos/" +
    idTipoVehiculo +
    "/" +
    idFamilia +
    "/" +
    modelo;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function numberFormat(value) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal", //currency
    currency: "MXN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  }).format(value);
}

export function addDefaultSrc(ev) {
  ev.target.src = global.foto_nd;
}

export function getInventarioCEDIS(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTInventariosUrl +
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo.toUpperCase() +
    "/" +
    idMarca;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function getInventarioDetalladoCEDIS(codigo) {
  var apiCall =
    global.apiRESTInventariosUrl + "/getInventarioDetalladoCEDIS/" + codigo.toUpperCase();
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function getInventarioCEDISAsync(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTInventariosUrl +
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo.toUpperCase() +
    "/" +
    idMarca;
  const response = await axios.get(apiCall, config).catch((error) => {
    return error;
  });

  try {
    if (response.status === 200) {
      //console.log("getInventarioCEDISAsync", response.data.result[0].inventario)
      return response.data.result[0].inventario;
    } else {
      return -1;
    }
  } catch (error) {
    console.log("error catch");
    return 0;
  }
}

export  function getInventarioNonAsync(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTInventariosUrl +
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo +
    "/" +
    idMarca;
  return axios
    .get(apiCall, config)
    .then((response) => {
    
      return response.data.result[0].inventario;
    })
    .catch((error) => {
      return -1;
    });
}

export function crearUser() {
  var apiCall = global.apiRESTUrlNET + "/login/CrearUser";
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function agregaPedidos(
  idClienteGovi,
  idAlmacen,
  total,
  consignado,
  idClienteWeb
) {
  var apiCall = global.apiRESTUrl + "/agregaPedidos";
  const response = await axios
    .post(apiCall, {
      idClienteGovi: idClienteGovi,
      idAlmacen: idAlmacen,
      total: total,
      consignado: consignado,
      idClienteWeb: idClienteWeb,
    }, config)

    .catch((error) => {
      console.log(error);
      return error;
    });

  try {
    if (response.data.status === "success") {
      return response.data.result[0].idPedido;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export async function agregaDetallePedidos(
  idPedido,
  producto,
  descripcion,
  cantidad,
  precio,
  idMarca,
  marca
) {
  var apiCall = global.apiRESTUrl + "/agregaDetallePedidos";

  const response = await axios
    .post(apiCall, {
      idPedido: idPedido,
      producto: producto,
      descripcion: descripcion,
      cantidad: cantidad,
      precio: precio,
      idMarca: idMarca,
      marca: marca,
    }, config)
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {
      return response.data.result[0].Message;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export function getPedidos(idCliente, idAlmacen, top) {
  var apiCall =
    global.apiRESTUrl +
    "/obtienePedidos/" +
    idCliente +
    "/" +
    idAlmacen +
    "/" +
    top;


  return axios
    .get(apiCall,config)
    .then((response) => {          
      return response.data;  
    })
    .catch((error) => {
     console.log(error);
      return error.response.data;
    });
}

export function getNumPedidos(idCliente, idAlmacen) {
  var apiCall =
    global.apiRESTUrl + "/obtieneNumPedidos/" + idCliente + "/" + idAlmacen;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getPedido(idPedido) {
  var apiCall = global.apiRESTUrl + "/obtienePedido/" + idPedido;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getAllVehiculos() {
  var apiCall = global.apiRESTUrl + "/obtieneTodosVehiculos";
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductosByCode(codigo, equivalencias) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCode/" +
    codigo +
    "/" +
    equivalencias;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}



export async function getProductosPlantillaByCode(equivalencias, codigo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosPlantillaCodigo/" +
    equivalencias +
    "/" +
    codigo;
  return await axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}


export function getProductosPlantilla() {
  const apiCall = global.apiRESTUrl + "/obtieneProductosPlantilla/";

  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductosByCodeDetalle(codigo) {
  const apiCall =
    global.apiRESTUrl + "/obtieneProductosByCodeDetalle/" + codigo;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductosByDescripcion(desc) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosByDescripcion/" + desc;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getMarcasProductosNuevos() {
  var apiCall = global.apiRESTUrl + "/obtieneMarcasProductosNuevos/";
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getFamiliasProductosNuevos(fechaInicial, fechaFinal, idMarca) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneFamiliasProductosNuevos/" +
    fechaInicial +
    "/" +
    fechaFinal +
    "/" +
    idMarca;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductosNuevos(
  fechaInicial,
  fechaFinal,
  idMarca,
  idFamilia
) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosNuevos/" +
    fechaInicial +
    "/" +
    fechaFinal +
    "/" +
    idMarca +
    "/" +
    idFamilia;    
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export function getProductosByDescCode(codigo) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosByDescCode/" + codigo;
  return axios
    .get(apiCall, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
export async function agregaPlantillaReabasto(
  idClienteWeb,
  idAlmacen,
  producto,
  VentaMensual,
  MinPza,
  MaxPza,
  promedio,
  diasMin,
  diasMax,
  inventario,
  idPedido
) {

    console.log("minPza: "+ MinPza);
 
  var apiCall = global.apiRESTUrl + "/agregaPedidoPlantilla";
  const response = await axios
    .post(apiCall, {
      idClienteWeb: idClienteWeb,
      idAlmacen: idAlmacen,
      producto: producto,
      ventaMensual: VentaMensual,
      minPza: MinPza,
      maxPza: MaxPza,
      promedio: promedio,
      diasMin: diasMin,
      diasMax: diasMax,
      inventario: inventario,
      idPedidoReabasto: idPedido
    }, config)
    .catch((error) => {
      console.log(error);
      return error;
    });
 
 
    try {
    if (response.data.status === "success") {
      return response;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

  export async function agregaReabastoEncabezado(
    idClienteWeb,
    idAlmacen,
  ) {
    var apiCall = global.apiRESTUrl + "/agregaPedidoReabastoEncabezado";
    const response = await axios
      .post(apiCall, {
        idClienteWeb: idClienteWeb,
        idAlmacen: idAlmacen,
      }, config)
      .catch((error) => {
        console.log(error);
        return error;
      });
      try {
        if (response.data.status === "success") {
          return response.data.result[0].idPedido;
          
        } else return 0;
      } catch (error) {
        return 0;
      }
}

export async function generaReabastoCliente(
  idPedidoReabasto
) {
  var apiCall = global.apiRESTUrl + "/generaPedidoReabastoCliente";
  const response = await axios
    .post(apiCall, {
      idPedidoReabasto: idPedidoReabasto,
    }, config)
    .catch((error) => {
      console.log(error);
      return error;
    });
    try {
      if (response.data.status === "success") {
        return 1;
        
      } else return 0;
    } catch (error) {
      return 0;
    }
}