import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert";

import { getNumPedidos } from "../repository";

const  SideBar = () => {

  const [cartItems, setCartItems]= useState(0);
  const [orderItems, setOrderItems] = useState(0);
  const [, setStatus] = useState(null);

  const getCartItems = () => {
    if (sessionStorage.getItem("cart") !== null) {
      let cart = JSON.parse(sessionStorage.getItem("cart"));
      setCartItems(Object.keys(cart).length);      
    }
  };

  const getNumOrders = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    var idCliente = user[0].idClienteGovi;    
    var idAlmacen = user[0].idSucursal;
    getNumPedidos(idCliente, idAlmacen)
      .then(response => {
        if (response.status === "success") {
          setStatus("success");
          setOrderItems(response.result[0].NumPedidos);        
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch(err =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
  };

  useEffect(() => {
   getCartItems();
   getNumOrders();
  }, []);

    return (
      <div className="col-md-3">
        <div className="card text-center bg-success text-white mb-3">
          <div className="card-body">
            <h3>Carrito</h3>
            <h4 className="display-4">
              <i className="fas fa-shopping-cart"></i>&nbsp;
              {cartItems}
            </h4>
            <Link to="/cart" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>

        <div className="card text-center bg-primary text-white mb-3">
          <div className="card-body">
            <h3>Inventarios</h3>
            <h4 className="display-4">
              <i className="fas fa-cubes"></i>&nbsp;
            </h4>
            <Link to="/stock" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>

        <div className="card text-center bg-warning text-white mb-3">
          <div className="card-body">
            <h3>Pedidos</h3>
            <h4 className="display-4">
              <i className="fas fa-folder-open"></i>&nbsp;
              {orderItems}
            </h4>
            <Link to="/orders" className="btn btn-outline-light btn-sm">
              Ver más
            </Link>
          </div>
        </div>
      </div>
    );
  }

export default SideBar;
