import React, {useState , useEffect, useRef} from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Logo from "../images/govinet.png";
import Footer from "../components/Footer";
import { loginNew2 } from "../repository";

const Login= () => {
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia]= useState("");
  const [rememberMe, setRememberme] = useState(true);
  const [loading, setloading] = useState(0);  
  
 const  toggleRememberMe = (e) => {       
    setRememberme(!rememberMe);  
 };

const submitLogin = (event) => { 
  event.preventDefault();   
  setloading(1);
  if (validator.current.allValid()) {
    loginNew2(usuario,contrasenia)
      .then((response) => {                     
        if (response.statusText === "OK" && response.data !== "") 
        {                                         
          if (rememberMe) 
          {            
            localStorage.setItem("usuario", usuario);
            localStorage.setItem("contrasenia", contrasenia);
            localStorage.setItem("rememberMe", rememberMe);
          } 
          else 
          {
            localStorage.setItem("usuario", "");
            localStorage.setItem("contrasenia", "");
            localStorage.setItem("rememberMe", rememberMe);
          }        
          window.location = "/home";                              
        }
        else
        {
             Swal("Error al Ingresar al Sistema", "El usuario no existe.", "error");
        }                
        setloading(0);
      })
      .catch((err) => {(
        Swal("Error de Conexón al Sistema", err, "error"));
        setloading(0);
      })
  } 
  else
   {
    setloading(0);
    validator.current.showMessages();   
    }
}

useEffect(() => {  
  validator.current.hideMessages();     
  var valor = localStorage.getItem("rememberMe");
 if(valor==="true")
 {  
    setRememberme(true);    
    setUsuario(localStorage.getItem("usuario"));
    setContrasenia(localStorage.getItem("contrasenia"));        
 }
}, []);

const validator = useRef(
  new SimpleReactValidator({
    element: (message) => (
      <span className="text-danger">
        <i className="fa fa-exclamation-circle" aria-hidden="true" />
        {` ${message}`}
      </span>
    ),
    messages: {
      default: "Campo Requerido",    
    },
  })
);

  return (
    <div>
      {/* NAVBAR */}
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-0">
        <div className="container">
          <div className="navbar-brand">&nbsp;GOVI Suspensión Automotriz</div>
        </div>
      </nav>

      {/* HEADER */}
      <header id="main-header" className="py-1 bg-warning text-black">
        <div className="container">
          <div className="row">
            <div className="col-md-0">
              &nbsp;
              <img src={Logo} width="78%" height="auto" alt="GoviNet" />  
              </div>
              <div className="col-md-0 mt-1">              
              <h2 className="float-left">&nbsp;Pedidos Mayoreo</h2>             
            </div>
          </div>
        </div>
      </header>

      {/* LOGIN */}
      <section id="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto mt-5">
              <div className="card">
                <div className="card-header">
                  <h4 className="text-center">Ingreso al Sistema</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={submitLogin}>
                    <div className="form-group">
                      <label htmlFor="usuario">Usuario</label>
                      <input
                        type="text"
                        name="usuario"
                        className="form-control"
                        onChange={(e) => setUsuario(e.target.value)}
                        defaultValue={usuario}
                        maxLength="10"
                        autoComplete="off"
                      />
                      {validator.current.message(
                        "usuario",
                        usuario,
                        "required|alpha_num"
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Contraseña</label>
                      <input
                        type="password"
                        name="contrasenia"
                        className="form-control"
                        onChange={(e) => setContrasenia(e.target.value)}
                        defaultValue={contrasenia}
                        maxLength="20"
                      />
                      {validator.current.message(
                        "contrasenia",
                        contrasenia,
                        "required|alpha_num"
                      )}
                    </div>

                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="chkRemember"
                        id="chkRemember"                                                          
                        onChange={(e) => toggleRememberMe(e)}   
                        checked={rememberMe}                                           
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="chkRemember"
                      >
                        Recordar Usuario y Contraseña
                      </label>
                    </div>

                    {loading === 0 && (
                      <input
                        type="submit"
                        value="Aceptar"
                        className="btn btn-warning btn-block mt-3"
                      />
                    )}

                    {loading === 1 && (
                      <div className="text-center mt-3">
                        <h6>Cargando...</h6>
                        <div
                          className="spinner-border text-warning"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Login;
