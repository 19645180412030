import React, { useState, useEffect, Fragment } from "react";
import Swal from "sweetalert";
import imageExists from "image-exists";
import NumericInput from "react-numeric-input";

import { numberFormat, getInventarioCEDIS } from "../repository";
import Global from "./Global";
import ProductApplications from "./ProductApplications";
import Modal360Viewer from "./Product360Viewer";
import ImageZoomViewer from "./ProductImageZoomViewer";

const ProductItem = (props) => {
  const [spinnerValue, setSpinerValue] = useState(1);
  const [inventario, setInventario] = useState(-1);
  const [imageSource, setImageSource] = useState("");
  const [is360, set360] = useState(false);
  const [isModal360Open, setIsModal360Open] = useState(false);
  const [isImageZoom, setIsImageZoom] = useState(false);
  const [codeSelected, setCodeSelected] = useState(null);
  //const [promocion] = useState(0);
  const idSucursal = React.useRef(JSON.parse(localStorage.getItem("sucursal")));
  const index = props.index;
  const item = props.item;

  const onChangeValue = (e) => {
    setSpinerValue(e);
  };

  const onClick = (e) => {
    props.add(props.item, spinnerValue);
  };

  const openImageZoomModal = (e) => {
    e.preventDefault();
    if (imageSource !== Global.foto_nd) {
      setIsImageZoom(true);
    }
  };

  const closeImageZoomModal = () => {
    setIsImageZoom(false);
  };

  function openAppsModal(e) {
    e.preventDefault();
    setCodeSelected(item);
    //codeSelected.current = props.item;
  }

  const closeAppsModal = () => {
    setCodeSelected(null);
  };

  const open360Modal = (e) => {
    e.preventDefault();
    setIsModal360Open(true);
  };

  const close360Modal = () => {
    setIsModal360Open(false);
  };

  const renderImageSource = (codigo) => {
    imageExists(
      Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg",
      function (exists) {
        if (exists) {
          setImageSource(
            Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg"
          );
          set360(true);
        } else {
          imageExists(
            Global.fotos_URL + codigo.toLowerCase().replace("-", "_") + ".jpg",
            function (exists) {
              if (exists) {
                setImageSource(
                  Global.fotos_URL +
                    codigo.toLowerCase().replace("-", "_") +
                    ".jpg"
                );
              } else {
                setImageSource(Global.foto_nd);
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    const ObtieneInventario = () => {
      getInventarioCEDIS(
        idSucursal.current,
        props.item.Codigo,
        props.item.idMarcaProducto
      )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.result.length > 0) {
              setInventario(response.data.result[0].inventario);
            }
          } else {
            Swal(
              "Error obtener la información [Inventarios]",
              response.message,
              "error"
            );
            setInventario(-1);
          }
        })
        .catch((err) => {
          // Swal("Error obtener la información [Inventarios]", err, "error");
          setInventario(-1);
        });
    };
    ObtieneInventario();
    renderImageSource(props.item.Codigo);
  }, [props.item.Codigo, props.item.idMarcaProducto]);

  const renderInventory = () => {
    const lblInv = <small>Inventario:&nbsp;</small>;
    return (
      <h5>
        {inventario === -1 ? (
          <span />
        ) : inventario > 0 ? (
          inventario > 10 ? (
            <div>
              {lblInv}
              <span className="badge badge-success">Disponible</span>
            </div>
          ) : (
            <div>
              {lblInv}
              <span className="badge badge-warning">Bajo</span>
            </div>
          )
        ) : (
          <div>
            {lblInv}
            <span className="badge badge-danger">No Disponible</span>
          </div>
        )}
      </h5>
    );
  };

  const renderPromocion = () => {
    return (
      <div>
        <h5>
          {item.Promocion === 1 ? (
            <div>
              <span className="badge badge-warning">* En Promoción *</span>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
        </h5>
      </div>
    );
  };
  const renderPrecioPromocion =()=>
  {
    return (
      <Fragment>
      { item.Promocion === 1 ? (
      
      <div className="col-xs text-right">
      <span className="badge badge-pill badge-light ">
        <del>${numberFormat(item.PrecioAnterior)}</del> 
      </span>&nbsp;
    </div> 
       
      ):("")
      }
    </Fragment>

    );
  
  }
  
  const estilo = '#FFDF95'

  const renderPromocional = () => {
    return item.Promocion === 1 ? estilo : "#00000008";
  };

  return (
    <div className="col-md-4 card-group">
      <div className="card mb-4 border-secondary">
        <div
          className="card-header text-center"
          style={{ backgroundColor: renderPromocional() }}
        >
          {props.item.Familia}
        </div>
        <img
          className="card-img-top"
          style={{
            cursor: "pointer",
            maxWidth: 100 + "%",
            height: 170 + "px",
            objectFit: is360 ? "cover" : "contain",
          }}
          src={imageSource}
          alt={props.item.Codigo}
          onClick={(e) => openImageZoomModal(e)}
          data-toggle="modal"
          data-target={"#modalImageZoom" + props.item.Codigo}
        />
        {isImageZoom && (
          <ImageZoomViewer
            item={props.item}
            imageSource={imageSource}
            closeModal={closeImageZoomModal}
          />
        )}
        {codeSelected && (
          <ProductApplications
            codigo={item.Codigo}
            closeModal={closeAppsModal}
          />
        )}
        <div className="card-body">
          <h5 className="card-title">
            <div className="row">
              <div className="col-xs">
                <div className="float-left">
                  <span className="badge badge-secondary">{item.Codigo}</span>                  
                </div>
              </div>
              <div className="col text-center">
                <div className="col text-center">
                  <button
                    className="btn btn-outline-warning btn-sm rounded-pill mr-2"
                    onClick={(e) => openAppsModal(e)}
                    data-toggle="modal"
                    data-target={"#modalApps" + item.Codigo}
                  >
                    APS
                  </button>
                  {is360 && (
                    <button
                      type="button"
                      className="btn btn-outline-info btn-sm rounded-pill"
                      onClick={(e) => open360Modal(e)}
                      data-toggle="modal"
                      value="360"
                      data-target={"#modal360" + item.Codigo + item.index}
                    >
                      360
                    </button>
                  )}
                  {isModal360Open && (
                    <Modal360Viewer
                      index={index}
                      item={item}
                      catalogo={true}
                      closeModal={close360Modal}
                    />
                  )}
                </div>
              </div>

                    {renderPrecioPromocion()}

              <div className="col-xs text-right">
                <span className="badge badge-pill badge-primary">
                  ${numberFormat(item.PrecioPesosMayoreo)}
                </span>
              </div>
            </div>
          </h5>
          <div className="row justify-content-center">
            <div className="align-self-center">{renderInventory()}</div>
          </div>
          <div className="row justify-content-center">
            <div className="align-self-center">{renderPromocion()}</div>
          </div>
        </div>

        {props.item.Descripcion ? (
          <div className="card-body card-text h-25">{item.Descripcion}</div>
        ) : (
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <strong>Modelo : </strong>
              {item.Modelo}
            </li>
            <li className="list-group-item">
              <strong>Posición : </strong>
              {item.Posicion}
            </li>
            <li className="list-group-item">
              <strong>Condición : </strong>
              {item.Condicion}
            </li>
          </ul>
        )}

        <div
          className="card-footer text-center"
          style={{ backgroundColor: renderPromocional() }}
        >
          {item && (
            <table className="table-sm w-100">
              <tbody>
                <tr>
                  <td className="text-left">
                    <NumericInput
                      name="spinner"
                      min={1}
                      max={999}
                      value={spinnerValue}
                      size={7}
                      mobile={true}
                      className="btn-lg"
                      onChange={(value) => {
                        onChangeValue(value);
                      }}
                      maxLength={3}
                      style={{
                        "btnUp.mobile": {
                          width: "5.0ex",
                          bottom: 2,
                          boxShadow: "none",
                          borderRadius: 2,
                          borderWidth: 1,
                          backgroundColor: renderPromocional(),
                        },

                        "btnDown.mobile": {
                          width: "5.0ex",
                          bottom: 2,
                          left: 2,
                          top: 2,
                          right: "auto",
                          boxShadow: "none",
                          borderRadius: 2,
                          borderWidth: 1,
                          backgroundColor: renderPromocional(),
                        },
                      }}
                    />
                  </td>
                  <td className="text-right">
                    <button
                      className="btn btn-warning btn-block"
                      type="button"
                      //  disabled={ (inventario - spinnerValue) < 0 && { true: false }}
                      onClick={() => onClick()}
                    >
                      <i className="fas fa-shopping-cart"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
