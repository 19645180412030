import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from "./screens/Login";
import Home from "./screens/Home";
import Search from "./screens/Search";
import Cart from "./screens/Cart";
import OrderDetails from "./components/OrderDetails";
import Orders from "./screens/Orders";
import Stock from "./screens/Stock";
import Template  from "./screens/Template"
import NotFound from "./screens/NotFound";
import { isAuthenticated } from "./repository";
import Users from "./screens/Users"
import TemplateReabasto from "./screens/TemplateReabasto";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>      
          <Route exact path="/" component={Login} />     

          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>

          <PrivateRoute exact path="/search">
            <Search />
          </PrivateRoute>

          <PrivateRoute exact path="/cart">
            <Cart />
          </PrivateRoute>

          <Route
            exact
            path="/orderDetails/:idPedido/:source"
            render={props => <OrderDetails {...props} />}
          />

          <PrivateRoute exact path="/orders">
            <Orders />
          </PrivateRoute>

          <PrivateRoute exact path="/stock">
            <Stock />
          </PrivateRoute>
          <PrivateRoute exact path="/template">
            <Template />
          </PrivateRoute>

          <PrivateRoute exact path="/Users">
            <Users />
          </PrivateRoute>

          <PrivateRoute exact path="/TemplateReabasto">
            <TemplateReabasto />
          </PrivateRoute>


          <Route component={NotFound} />

          {/*
          <PrivateRoute exact path="/test">
            <Test />
          </PrivateRoute>
          */}
        </Switch>
      </BrowserRouter>
    );
  }
}

const auth = isAuthenticated();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...props }) {
  //console.log("auth: " + auth);

  return (
    <Route
      {...props}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default Router;
