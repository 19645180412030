import React, {useState, useEffect } from "react";
import Swal from "sweetalert";

import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SearchByMaker from "./SearchByMaker";
import SearchByVehicle from "./SearchByVehicle";
import SearchByCode from "./SearchByCode";
import SearchByDesc from "./SearchByDesc";
import NewProducts from "../components/NewProducts";
import { getInventarioCEDISAsync } from "../repository";

const Search = () => {
  const [idSucursal] = useState(JSON.parse(localStorage.getItem("sucursal")));
  const navBarRef = React.useRef(NavBar);
 
 useEffect(() => {     
  navBarRef.current.getCartItems();
  }, []);
  
  async function addToCart (item, cant)  {    
    try {     
      let cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : {};   
      let idProducto = item.idProducto;     
      cart[idProducto] = cart[idProducto] ? cart[idProducto] : item;
      var numero = cart[idProducto].Cantidad;     
      if(numero === undefined)
      {     
        cart[idProducto].Cantidad = 0;
      }
      cart[idProducto].Cantidad += cant;    
      //Calculo el Inventario
      let inventario = await getInventarioCEDISAsync(idSucursal,item.Codigo,item.idMarcaProducto); 
      //console.log(inventario);
      //validacion cuando inventario no se pueda consultar se asignan 100 del producto para pasar el pedido
      if(inventario === undefined)
      {
        cart[idProducto].Inventario = 100;   
      }
      else
      {  
      cart[idProducto].Inventario = inventario;     
      }      
      sessionStorage.setItem("cart", JSON.stringify(cart));           
      navBarRef.current.getCartItems();
      Swal(
        "Producto agregado",
        "Cuenta con [" +
          cart[idProducto].Cantidad +
          "] productos [" +
          cart[idProducto].Codigo +
          "] en su Carrito de Compras.",
        "success"
      );
    } catch (error) {
      Swal(
        "Error en Carrito de Compras",
        "No se pudo agregar su Producto al Carrito de Compras.",
        "error"
      );
    }
  };
    return (
      <div>
        {/* NAVBAR */}
        <NavBar ref={navBarRef} />

        {/* HEADER */}
        <Header
          title="Búsqueda de Productos"
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-search"
        />
        {/* CONTENT */}
        <section id="search">
          <div className="container">
            <div className="row mt-3">
              <div className="col-md-12">
                <nav>
                  <div
                    className="nav nav-tabs bg-light"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link active"
                      id="nav-1-tab"
                      data-toggle="tab"
                      href="#nav-1"
                      role="tab"
                      aria-controls="nav-1"
                      aria-selected="true"
                    >
                      Vehículo
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-2-tab"
                      data-toggle="tab"
                      href="#nav-2"
                      role="tab"
                      aria-controls="nav-2"
                      aria-selected="false"
                    >
                      Marca
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-3-tab"
                      data-toggle="tab"
                      href="#nav-3"
                      role="tab"
                      aria-controls="nav-3"
                      aria-selected="false"
                    >
                      Código
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-4-tab"
                      data-toggle="tab"
                      href="#nav-4"
                      role="tab"
                      aria-controls="nav-4"
                      aria-selected="false"
                    >
                      Descripción
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-4-tab"
                      data-toggle="tab"
                      href="#nav-5"
                      role="tab"
                      aria-controls="nav-4"
                      aria-selected="false"
                    >
                      Productos Nuevos
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-1"
                    role="tabpanel"
                    aria-labelledby="nav-1-tab"
                  >
                    <SearchByVehicle addToCart={addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-2"
                    role="tabpanel"
                    aria-labelledby="nav-2-tab"
                  >
                    <SearchByMaker addToCart={addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-3"
                    role="tabpanel"
                    aria-labelledby="nav-3-tab"
                  >
                    <SearchByCode addToCart={addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-4"
                    role="tabpanel"
                    aria-labelledby="nav-4-tab"
                  >
                    <SearchByDesc addToCart={addToCart} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-5"
                    role="tabpanel"
                    aria-labelledby="nav-4-tab"
                  >
                    <NewProducts addToCart={addToCart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer />
      </div>
    );
  }


export default Search;
