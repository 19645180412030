import React, { Fragment, useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import {
  getMarcasProductosNuevos,
  getFamiliasProductosNuevos,
  getProductosNuevos,
} from "../repository";
import ProductItem from "./ProductItem";

const NewProducts = (props) => {
  var fechaInicio = new Date();
  fechaInicio.setMonth(fechaInicio.getMonth() - 2);
  const [status, setStatus] = useState(null);
  const [idMarca, setIdMarca] = useState(0); 
  const [idFamilia, setIdFamilia] = useState(-1);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(0);
  const fechaInicial = fechaInicio.toISOString().substring(0, 10)
  const fechaFinal = new Date().toISOString().substring(0, 10)

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  useEffect(() => {
    obtieneMarcas();
    validator.current.hideMessages();

    //setFechaInicial();
    // setFechaFinal();
  }, []);

  const handleComboChange = (event) => {
    if (event.target.name === "idMarca") {    
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      setIdMarca(event.target.value);      
      console.log("idMarca:" + idMarca + " event:" + event.target.value);
      //  obtieneVehiculos(event.target.value);
      //obtieneModelos(0);
      obtieneFamiliasProductosNuevos(event.target.value);
    }
    if (event.target.name === "idFamilia") {
      setIdFamilia(event.target.value);
      setProductos([]);
      setStatus(null);
    }
  };

  const obtieneMarcas = () => {
    getMarcasProductosNuevos()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idMarca, text: item.Marca };
          });
          setMarcas( [
            { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
          ].concat(items));
        } else {
          Swal(
            "Error obtener la información [Marcas]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Marcas]", err, "error")
      );
  };

  const obtieneFamiliasProductosNuevos = (idMarcaP   
  ) => {
    getFamiliasProductosNuevos(fechaInicial, fechaFinal,idMarcaP)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idFamilia, text: item.Familia };
          });
          setFamilias(
            [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Vehículos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Vehículos]", err, "error")
      );
  };

  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);
    if (validator.current.allValid()) {
      getProductosNuevos(fechaInicial, fechaFinal, idMarca, idFamilia)
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);
          } else {
            setLoading(0);
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });     
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };
  return (
    <div>
      {/* CONTENT */}
      <section id="searchContent">
        <form onSubmit={search}>
          <div className="container">
            <div className="row mt-3">
              <div className="col-md-3 mb-3">
                <h6>Marca:</h6>
                <select
                  name="idMarca"
                  key="idMarca"
                  className="form-control"
                  onChange={handleComboChange}
                >
                  {marcas.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                {validator.current.message(
                  "idMarca",
                  idMarca,
                  "required|numeric|min:1,num"
                )}
              </div>
              <div className="col-md-3">
                <h6>Familia:</h6>
                <select
                  name="idFamilia"
                  key="idFamilia"
                  className="form-control"
                  onChange={handleComboChange}
                >
                  {familias.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                {validator.current.message(
                  "idFamilia",
                  idFamilia,
                  "required|numeric|min:0,num"
                )}
              </div>
              <div className="col-md-3">
              <div className="col-md-9">&nbsp;</div> 
                <button className="btn btn-warning btn-block" type="submit">
                  <i className="fas fa-search mr-1"></i>Buscar
                </button>
              </div>


            </div>
            <div className="row mt-3">
             
             
            </div>
            {loading === 1 && (
              <div className="text-center mb-3 mt-3">
                <h6>Cargando...</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            )}
            {status === "success" && (
              <Fragment>
                <div className="row mt-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    <h6>
                      <span className="badge badge-warning ml-1 mr-1">
                        {productos.length}
                      </span>
                      <small>
                        <strong>Producto(s) encontrado(s)</strong>
                      </small>
                    </h6>
                    <div className="col-md-3"></div>
                  </div>
                </div>
                <div className="row">
                  {productos.map((item, index) => (
                    <ProductItem
                      item={item}
                      index={index}
                      key={index}
                      add={props.addToCart}
                    />
                  ))}
                </div>
              </Fragment>
            )}
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewProducts;
