import React, { useState, useEffect } from "react";
import NumericInput from "react-numeric-input";
import ImageZoomViewer from "./ProductImageZoomViewer";
import Global from "./Global";
import imageExists from "image-exists";
import Modal360Viewer from "./Product360Viewer";
import ProductApplications from ".//ProductApplications";
import { numberFormat } from "../repository";

const CartItem = (props) => {
  const [item] = useState(props.item);
  const [index] = useState(props.item.index);
  const [spinnerValue, setSpinnerValue] = useState(item.Cantidad);
  const [inventario] = useState(item.Inventario);
  const [is360, set360] = useState(false);
  const [isModal360Open, setIsModal360Open] = useState(false);
  const [isImageZoom, setIsImageZoom] = useState(false);
  const [codeSelected, setCodeSelected] = useState(null);
  const [imageSource, setImageSource] = useState("");

  const openImageZoomModal = (e) => {
    e.preventDefault();
    if (imageSource !== Global.foto_nd) {
      setIsImageZoom(true);
    }
  };

  function openAppsModal(e) {
    e.preventDefault();
    setCodeSelected(item);    
  }

  const closeAppsModal = () => {
    setCodeSelected(null);
  };

  const open360Modal = (e) => {
    e.preventDefault();
    setIsModal360Open(true);
  };

  const close360Modal = () => {
    setIsModal360Open(false);
  };
  useEffect(() => {
   // console.log(props);
    renderImageSource(props.item.Codigo);
  }, [props.item.Codigo]);

  const renderImageSource = (codigo) => {
    imageExists(
      Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg",
      function (exists) {
        if (exists) {
          setImageSource(
            Global.urlFotos360 +
              codigo.toUpperCase() +
              "/images/img_0_0_0.jpg"
          );
          set360(true);
        } else {
          imageExists(
            Global.fotos_URL + codigo.toLowerCase().replace("-", "_") + ".jpg",
            function (exists) {
              if (exists) {
                setImageSource(
                  Global.fotos_URL +
                    codigo.toLowerCase().replace("-", "_") +
                    ".jpg"
                );
              } else {
                setImageSource(Global.foto_nd);
              }
            }
          );
        }
      }
    );
  };

  const renderInventory = () => {   
    const lblInv = <small>Inventario:&nbsp;</small>
    return (   
      <h5>
        {inventario === -1 ? (
          <span />
        ) : (inventario - spinnerValue)  >= 0 ? (
          (inventario - spinnerValue)  > 10 ? (
            <div>{lblInv}<span className="badge badge-success">Disponible</span></div>
          ) : (
            <div>{lblInv}<span className="badge badge-warning">Bajo</span></div>
          )
        ) : (
          <div>{lblInv}<span className="badge badge-danger">No Disponible</span></div>
        )}
      </h5>
    );
  };

  const closeImageZoomModal = () => {
    setIsImageZoom(false);
  };

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-md-3 text-center">        
          <img
            className="card-img-top"
            style={{
              cursor: "pointer",
              maxWidth: 100 + "%",
              height: 170 + "px",
              objectFit: is360 ? "cover" : "contain",
            }}
            src={imageSource}
            alt={props.item.Codigo}
            onClick={(e) => openImageZoomModal(e)}
            data-toggle="modal"
            data-target={"#modalImageZoom" + props.item.Codigo}
          />
          <div className="col text-center mt-3">
            <div className="col text-center"></div>
            {isImageZoom && (
              <ImageZoomViewer
                item={props.item}
                imageSource={imageSource}
                closeModal={closeImageZoomModal}
              />
            )}
            {codeSelected && (
              <ProductApplications
                codigo={item.Codigo}
                closeModal={closeAppsModal}
              />
            )}
            <button
              className="btn btn-outline-warning btn-sm rounded-pill mr-2"
              onClick={(e) => openAppsModal(e)}
              data-toggle="modal"
              data-target={"#modalApps" + item.Codigo}
            >
              APS
            </button>
            {is360 && (
              <button
                type="button"
                className="btn btn-outline-info btn-sm rounded-pill"
                onClick={(e) => open360Modal(e)}
                data-toggle="modal"
                value="360"
                data-target={"#modal360" + item.Codigo + item.index}
              >
                360
              </button>
            )}
            {isModal360Open && (
              <Modal360Viewer
                index={index}
                item={item}
                catalogo={true}
                closeModal={close360Modal}
              />
            )}
          </div>
        </div>
        <div className="col-md-9 text-center">
          <h5>
            <span className="badge badge-secondary float-left mt-1">
              {item.Codigo}
            </span>
            </h5>

            { renderInventory()}
            
            {/*
            {inventario >= spinnerValue ? (
              <span className="badge badge-success mt-1">Disponible</span>
            ) : (
              <span className="badge badge-danger mt-1">No Disponible</span>
            )}
            */}
            <h5>
            <span className="badge badge-pill badge-primary float-right mt-1">
              ${numberFormat(item.PrecioPesosMayoreo)}
            </span>
          </h5>
          <div className="text-center">
            {item.Descripcion
              ? item.Descripcion
              : item.Familia +
                " / " +
                item.Marca +
                " / " +
                item.TipoVehiculo +
                " / " +
                item.Modelo +
                " / " +
                item.Posicion +
                " / " +
                item.Condicion}
          </div>
          <div className="text-center mt-3">
            <NumericInput
              name="spinner"
              key={item.idProducto}
              min={1}
              max={999}
              value={spinnerValue}
              size={7}
              mobile={true}
              className="btn-lg"
              onChange={(value) => {
                setSpinnerValue(
                  isNaN(Number.parseInt(value)) ? 1 : Number.parseInt(value),
                  props.update(
                    item,
                    isNaN(Number.parseInt(value)) ? 1 : Number.parseInt(value)
                  )
                );               
              }}
              maxLength={3}
              style={{
                "btnUp.mobile": {
                  width: "5.0ex",
                  bottom: 2,
                  boxShadow: "none",
                  borderRadius: 2,
                  borderWidth: 1,
                },

                "btnDown.mobile": {
                  width: "5.0ex",
                  bottom: 2,
                  left: 2,
                  top: 2,
                  right: "auto",
                  boxShadow: "none",
                  borderRadius: 2,
                  borderWidth: 1,
                },
              }}
            />

            <button
              className="btn btn-secondary ml-5"
              type="button"
              onClick={() => props.remove(item)}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
