import React, { Fragment , useRef, useState, useEffect} from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import { getProductosByDescripcion } from "../repository";
import ProductItem from "../components/ProductItem";

const  SearchByDesc = (props) => {
  const descRef = React.useRef("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(0);
  const [productos, setProductos] = useState([]);
  const [status, setStatus] = useState(null);
  const [prop] = useState(props);  
 const validator = useRef(
  new SimpleReactValidator({
    element: (message) => (
      <span className="text-danger">
        <i className="fa fa-exclamation-circle" aria-hidden="true" />
        {` ${message}`}
      </span>
    ),
    messages: {
      default: "Campo Requerido",    
    },
  })
);
  
  const  changeState = () => {
      setDesc(descRef.current.value);
      setProductos([]);
      setStatus(null);    
  };

  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);    
    if (validator.current.allValid()) {      
      getProductosByDescripcion(desc)
        .then((response) => {          
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);            
          } else {
             setLoading(0);           
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);          
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {     
      validator.current.showMessages();      
      setLoading(0);
      }
  };

  
useEffect(() => {  
  validator.current.hideMessages();
}, []);
    return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form onSubmit={search}>
            <div className="container">
              <div className="row">
                <div className="col-md-2 mt-2">
                  <h6>Descripción:</h6>
                </div>
                <div className="col-md-7 mb-3">
                  <input
                    name="txtDesc"
                    type="text"
                    className="form-control"
                    defaultValue=""
                    ref={descRef}
                    autoComplete="off"
                    placeholder="Ejemplo: tsuru, aveo 2018, rotula civic 2012"
                    onChange={changeState}
                    maxLength="50"
                  />
                  {validator.current.message(
                    "txtDesc",
                    desc,
                    "required|alpha_num_dash_space"
                  )}
                </div>
                <div className="col-md-3">
                  <button className="btn btn-warning btn-block" type="submit">
                    <i className="fas fa-search mr-1"></i>Buscar
                  </button>
                </div>

              </div>
              {loading === 1 && (
                <div className="text-center mb-3 mt-3">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem
                        item={item}
                        index={index}
                        add={prop.addToCart}
                        key = {index}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
      </div>
    );
  }
export default SearchByDesc;
