import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import readXlsxFileBrowser from "read-excel-file";
import global from "../components/Global";
import {
  getProductosPlantillaByCode,
  agregaPlantillaReabasto,
  agregaReabastoEncabezado,
  generaReabastoCliente,
} from "../repository";

const TemplateReabasto = () => {
  const [input, setInput] = React.useState(null);
  const [productos, setProductos] = useState([]);
  //const [idSucursal] = useState(JSON.parse(localStorage.getItem("sucursal")));
  const navBarRef = React.useRef(NavBar);
  const [equivalencias, setEquivalencias] = useState(false);
  const [success, setSuccess] = useState(null);
  const [plantillaErronea, setPlantillaErronea] = useState(false);
  const productosValidos = useRef(0);
  const [productoCargado, setProductoCargado] = useState(0);
  const [idPedidoReabasto, setIdPedidoReabasto] = useState(0);
 // const [idPedidoCargado, setIdPedidoCargado] = useState(0);

  const schema = {
    Producto: {
      prop: "Producto",
      type: String,
    },
    VentaMensual: {
      prop: "VentaMensual",
      type: Number,
    },
    MinPiezas: {
      prop: "MinPiezas",
      type: Number,
    },
    MaxPiezas: {
      prop: "MaxPiezas",
      type: Number,
    },
    PromedioVenta: {
      prop: "PromedioVenta",
      type: Number,
    },
    DiasMinimo: {
      prop: "DiasMinimo",
      type: Number,
    },
    DiasMaximo: {
      prop: "DiasMaximo",
      type: Number,
    },
    Inventario: {
      prop: "Inventario",
      type: Number,
    },
  };

  const cargarExcel = async (e) => {
    e.preventDefault();

    try {
      let xml = await readXlsxFileBrowser(input.files[0], {
        schema,
        transformData(data) {
          // Formateamos el resultado.
          return [["Producto", "VentaMensual","MinPiezas","MaxPiezas","PromedioVenta","DiasMinimo","DiasMaximo","Inventario"]].concat(data);
        },
      });

      //Se remueven las primeras 2 filas del excel y el LOGO
      const elementosABuscar = Object.assign([], xml.rows);
      elementosABuscar.splice(0, 1);

      if (elementosABuscar.length < global.cantidadMaximaPlantilla) {
        setProductos(elementosABuscar);
      } else {
        Swal(
          "Aviso.",
          "Solo se permite la carga de hasta " +
            global.cantidadMaximaPlantilla +
            " códigos por plantilla.",
          "info"
        );
        setPlantillaErronea(true);
      }
    } catch (ex) {
      Swal(
        "Error.",
        "Archivo No Valido. Favor de descargar la nueva Plantilla." ,
        "error"
      );
          console.log(ex);
      setPlantillaErronea(true);
    }
  };

  useEffect(() => {
    if (
      success === "success" &&
      productoCargado >= productosValidos.current &&
      productosValidos.current > 0
    ) {
      console.log(productoCargado + " de " + productosValidos.current);
      console.log("REABASTO A PROCESAR:" +idPedidoReabasto);
      generaReabastoCliente(idPedidoReabasto)
     .then(() => 
       {
        
         console.log("pedido rebasto terminado");      
       });
  

      setTimeout(function () {
        window.location = "/home";
      }, 1000);
    } else {
      setInput(document.getElementById("input"));
    }
  }, [success, productoCargado]);

  async function CargaItemsAlCarrito() {
     //OBTENEMOS LOS DATOS DEL USUARIO QUE CARGARA LA PLANTILLA.
     var user1 = JSON.parse(sessionStorage.getItem("usuario"));
      var idCliente1 = user1[0].idCliente;
      var idAlmacen1 = user1[0].idSucursal;
  
      var idPedido = await agregaReabastoEncabezado(
        idCliente1,
        idAlmacen1,
      ).then( function(response)
      {
             console.log(response);
             var idPedidoDB = response;
             setIdPedidoReabasto(response);
    var contador = 0;
    var itemsValidos = 0;
    let promise;
    let promise2;
    //validamos que se haya ingresado informacion en el excel
    if (productos.length > 0) {
      productos.forEach((item, index) => {
        //var cant = item.Cantidad;
        promise = new Promise((resolve, reject) => {
          getProductosPlantillaByCode(equivalencias ? 1 : 0, item.Producto)
            .then(async function (response) {
              //validamos la respuesta de un producto que si existe
              if (response.result !== undefined) {
                await response.result.forEach((itemWebAPI) => {
                  itemsValidos = itemsValidos + 1;
                  productosValidos.current = itemsValidos;
                  itemWebAPI.Cantidad = item.Cantidad; 
                      try {
                        var user = JSON.parse(sessionStorage.getItem("usuario"));
                         var idCliente = user[0].idCliente;
                         var idAlmacen= user[0].idSucursal;
                         //GUARDAMOS DETALLE DEL PLANTILLA
                        agregaPlantillaReabasto(  idCliente,  idAlmacen, item.Producto,  item.VentaMensual,  item.MinPiezas, item.MaxPiezas, item.PromedioVenta, item.DiasMinimo,item.DiasMaximo,item.Inventario, idPedidoDB)
                        .then(async function (response1)
                        {
                            console.log(response1);                
                          contador = contador + 1;
                          setProductoCargado(contador);
                        });                   
                      } catch (error) {
                          console.log(error);
                      }
                });
              
                  resolve();

              }
            })
            .catch((error) => {
              console.log(error);
            });

          if (productos.length - 1 === index) {
            console.log("HE PROCESADO TODOS LOS PRODUCTOS");


          }
        });
      });

    

    }
     else {
      Swal(
        "Aviso.",
        "Favor de validar la información de la Plantilla.",
        "info"
      );
    }



   
   

      });
      
 
    
    //let result2 = await promise2;

    //console.log(result);
    //console.log(result2);
  
  
  }

  const cargarItems = async (e) => {
    e.preventDefault();

    await CargaItemsAlCarrito().then(() => {
      setSuccess("success");
    });
  };

  return (
    <div>
      {/* NAVBAR */}
      <NavBar ref={navBarRef} />
      {/* HEADER */}
      <Header
        title="Subir Plantilla"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-upload"
      />
      {/* CONTENT */}
      <section id="plantilla">
        <form>
          {
            /*success === "success" &&*/ productoCargado > 0 ? (
              <div className="text-center mb-3 mt-3">
                <h6>Espere por favor... Actualizando sus productos</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col"></div>
                  <div className="col-xs">
                    <a
                      className={
                        plantillaErronea === false
                          ? "btn btn-link"
                          : "btn btn-danger"
                      }
                      href={process.env.PUBLIC_URL + "/plantillaReabasto.xlsx"}
                    >
                      Bajar  Plantilla
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    Ingrese el archivo Excel con su configuración de reabasto:
                  </div>
                </div>
                <div className="row">
                  <div className="col mt-2">
                    <input
                      type="file"
                      className="form-control-file"
                      accept=".xlsx"
                      id="input"
                      name="input"
                      onChange={cargarExcel}
                    />
                  </div>
                  <div className="col-md-auto mt-2">
                    <div className="row justify-content-center">
                      <div className="col">
                        <button
                          className="btn btn-warning btn-block"
                          id="btnCargar"
                          name="btnCargar"
                          onClick={(e) => cargarItems(e)}
                        >
                          <i className="fa fa-upload"></i>
                          &nbsp; Subir Archivo
                        </button>
                      </div>
                      <div className="col-md-auto">
                        <div className="col text-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={equivalencias.current}
                            onChange={() => {
                              setEquivalencias(!equivalencias);
                            }}
                            name="Equivalencias"
                          />
                          <label className="form-check-label">
                            Procesar Equivalencias
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                 
              </div>
            )
          }
        </form>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default TemplateReabasto;
