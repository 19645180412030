import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Swal from "sweetalert";
import NavBarItem from "./NavBarItem";
import { getNumPedidos } from "../repository";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      cartItems: 0,
      orderItems: 0,
    };
  }

  logOut() {
    sessionStorage.clear();
  }

  getCartItems = () => {
    if (sessionStorage.getItem("cart") !== null) {
      let cart = JSON.parse(sessionStorage.getItem("cart"));     
      this.setState({
        cartItems: Object.keys(cart).length,
      });
    }
  };

  getNumOrders = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));   
    var idCliente = user[0].idClienteGovi;
    var idAlmacen= user[0].idSucursal;

    getNumPedidos(idCliente, idAlmacen)
      .then((response) => {
        if (response.status === "success") {         
          this.setState({
            status: "success",
            orderItems: response.result[0].NumPedidos,
          });         
        } else {
          Swal(
            "Error obtener la información [Pedidos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Pedidos]", err, "error")
      );
  };

  componentDidMount() {
    this.getCartItems();
    this.getNumOrders();
  }

  render() {
    var user = JSON.parse(sessionStorage.getItem("usuario"));    

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-2 sticky-top">
        <div className="container">
          <NavLink to="/home" className="navbar-brand mb-2 text-white-50">           
            &nbsp;GoviNet
          </NavLink>

          <Link to="/cart" className="navbar-toggler text-white">
            <i className="fas fa-shopping-cart mr-2"></i>
            <span className="badge badge-success">{this.state.cartItems}</span>
          </Link>

          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <small className="mr-2">MENU</small>
            <i className="fas fa-bars"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              {user.map((item, index) => (
                <NavBarItem
                  item={item}
                  key={index}
                  cartItems={this.state.cartItems}
                  orderItems={this.state.orderItems}
                />
              ))}
            </ul>
            <ul className="navbar-nav ml-2">
              <li className="nav-item">
                <a className="nav-link" href="/" onClick={this.logOut}>
                  <h6>Salir</h6>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
